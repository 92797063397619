
export default function initOnHoverNav() {
  const navDropdowns = document.querySelectorAll('.nav-item.dropdown:not(.no-hover-dropdown)');
    const hoverHandler = (e) => {
        if (window.matchMedia("(min-width: 1200px)").matches) {
            if (e.type === 'mouseenter') {
                e.currentTarget.classList.add('show');
                e.currentTarget.querySelector('.dropdown-menu').classList.add('show');
            } else {
                e.currentTarget.classList.remove('show');
                e.currentTarget.querySelector('.dropdown-menu').classList.remove('show');
            }
        }
    };

    //meme listener pour deux evenement:
    "mouseenter mouseleave".split(" ").forEach(function(e){
        navDropdowns.forEach(item => {
            item.addEventListener(e, hoverHandler, false);
        })
    });

    // MENU : Gestion de l'affichage des ssMenu
    const tabNavLink =  document.querySelectorAll('#navbarCollapse .navbar-nav > li.dropdown');
    if (window.matchMedia("(max-width: 1200px)").matches) {
        if ( tabNavLink.length !== 0 ) {
            tabNavLink.forEach(item => {
                item.addEventListener("click", (e) => {
                    if ( item.classList.contains('dropdown')) {
                        const dropdownMenuHeight = item.querySelector('.megamenu__container').offsetHeight; // Height de la DIV du ssMenu ouverte
                        const headerHeight = document.getElementById('header').offsetHeight; // Height du header
                        const innerHeight = screen.availHeight; // Height du viewport
                        const newGlobal = innerHeight-(headerHeight+75); // Nouvelle Height du ssMenu à setter (75= hauteur du lien défini en css)
                        //console.log('Hauteur du header : ' + headerHeight);
                        //console.log('Hauteur div à ouvrir : ' + dropdownMenuHeight);
                        //console.log('Hauteur viewport : ' + innerHeight);
                        //console.log('Hauteur newglobal : ' + newGlobal);
                        if ( newGlobal < dropdownMenuHeight ) {
                            const style = item.querySelector('.megamenu__container').getAttribute('style'); // On récupère l'attribut « style »
                            item.querySelector('.megamenu__container').setAttribute('style', 'height:'+newGlobal+'px; overflow-y: scroll;'); // On édite l'attribut « style »
                        }
                    }
                    // Vérifier si on a la classe "active" et on cache les autres items ou pas
                    if (item.classList.contains('active')) {
                        item.classList.remove('active');
                        const tabNavLinkToHide = document.querySelectorAll('#navbarCollapse .navbar-nav > li:not(.active)');
                        tabNavLinkToHide.forEach(link => {
                            link.classList.remove('hide-element');
                        });
                    } else {
                        item.classList.add('active');
                        const tabNavLinkToHide = document.querySelectorAll('#navbarCollapse .navbar-nav > li:not(.active)');
                        tabNavLinkToHide.forEach(link => {
                            link.classList.add('hide-element');
                        });
                    }
                },
                false);
            })
        }
    }

    // MENU : Bouton ouverture/fermeture du menu. Supprimer les classes sur les items pour tout réinitialiser
    const btnNavbarToggler = document.getElementById('navbarToggler');
    try {
        btnNavbarToggler.addEventListener("click", (e) => {
            // Ajout scrollBar vertical si le viewport est inférieur à la taille du HEADER + NAVBAR-COLLAPSE ouverte!
            const navbarCollapse = document.getElementById('navbarCollapse');
            if ( navbarCollapse ) {
                navbarCollapse.addEventListener('shown.bs.collapse', function(event){
                    const navbarCollapseHeight = navbarCollapse.offsetHeight; // Height de la DIV du ssMenu ouverte
                    const headerHeight = document.getElementById('header').offsetHeight; // Height du haeder
                    const globalHeight = navbarCollapseHeight+headerHeight; // Height totale "théorique"
                    const innerHeight = screen.availHeight; // Height du viewport
                    const newGlobal = innerHeight-headerHeight; // Nouvelle Height du ssMenu à setter
                    if ( innerHeight < globalHeight ) {
                        const style = navbarCollapse.getAttribute('style'); // On récupère l'attribut « style »
                        navbarCollapse.setAttribute('style', 'height:'+newGlobal+'px; overflow-y: scroll;'); // On édite l'attribut « style »
                    }
                }, false);
            }
            else {
                console.log('Il manque le block avec l\'id "navbarCollapse" pour afficher le ssMenu Mobile')
            }
        });
    } catch (error) {
        console.log('Il manque le bouton avec l\'id "navbarToggler" pour afficher le menu Mobile')
    }

}