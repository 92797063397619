// Example starter JavaScript for disabling form submissions if there are invalid fields
export default function formValidator() {
    'use strict';
    window.addEventListener('load', function() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function(form) {
            form.addEventListener('submit', function(event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }

                // Vérifie si le SELECT est valide ou pas et ajoute une classe (pour "choices.js")
                const tabSelect = document.querySelectorAll('select.choices__input');
                if ( tabSelect ) {
                    tabSelect.forEach(function (elt) {
                        if ( elt.checkValidity() ) {
                            elt.closest("div").classList.add('valid');
                        }
                        else {
                            elt.closest("div").classList.add('invalid');
                        }
                    });
                }

                form.classList.add('was-validated');
            }, false);
        });
    }, false);
};