import 'bootstrap.native';
import 'lazysizes';

import formValidator from "./contact/_form-validator";
import navHover from "./components/_nav";
import toastify from "./components/_toastify";
import BSN from 'bootstrap.native/dist/bootstrap-native';

window.addEventListener('DOMContentLoaded', () => {
    BSN.initCallback();

    // MENU PRINCIPAL : Hover sur les liens en desktop
    navHover();
    window._bsn = BSN;
    // FlashMessages
    if ( document.querySelector('#flashMessage')) {
        let toastifyClass = document.querySelector('#flashMessage').dataset.classname;
        let toastifyText = document.querySelector('#flashMessage').dataset.text;
        toastify(toastifyText, toastifyClass);
    }

    const btnbacktotop = document.getElementById('backtotop');
    if (btnbacktotop) {
        btnbacktotop.addEventListener('click', (evt) => {
            // HAUT DE PAGE ! ATTENTION, ne marche pas avec un <header> en fixed!
            //document.getElementById("header").scrollIntoView({behavior: "smooth"});
            // Hack ci-dessous
            var topOfElement = document.querySelector('#header').offsetTop - 96;
            window.scroll({ top: topOfElement, behavior: "smooth" });
            evt.preventDefault();
        });
    }

    // Validation Formulaires Bootstrap
    formValidator();
});
